// THIS CLASS IS FOR GETTING - SETTING - AND REMOVING COOKIES
// THIS 'CryptoCookie' INSTANCE IS IMPORTED INTO THE VUE
// MAIN.JS FILE AND IS ADDED TO THE VUE INSTANCE UNDER THE
// PROTOTYPE VARIABLE OF 'Vue.prototype.$cryptograph'

export class CryptoCookie {
  // Setting instance properties for cryptograph package and vue-cookie --------
  constructor(vueInstance) {
    this._vm = Object.assign(vueInstance, vueInstance.prototype);
    this.cookies = this._vm.$cookies;
    this.crypto = this._vm.$cryptograph;
  }
  // This function encrypts the payload and payloadKey and sets the cookie -----
  // The function returns the arguments called.
  setCookie(payloadKey, payloadValue, time = Infinity) {
    this.cookies.set(
      this.crypto.encrypt(payloadKey),
      this.crypto.encrypt(payloadValue),
      time
    );
    return { key: payloadKey, value: payloadValue };
  }
  // This function loops through the keys of each cookie decrypting them and ---
  // and checking them against the payloadKey that was submitted. If a match is
  // found, the value associated with the key is then decrypted and the
  // function returns the arguments called if successful, else null.
  getCookie(payloadKey) {
    let cookieKey;
    let cookieValue;
    this.cookies.keys().forEach((key) => {
      const dCookieKey = this.crypto.decrypt(key);
      if (dCookieKey === payloadKey) {
        cookieKey = key;
      }
    });
    cookieValue = cookieKey
      ? this.crypto.decrypt(this.cookies.get(cookieKey))
      : null;
    return cookieValue ? { key: payloadKey, cookie: cookieValue } : null;
  }
  // This function loops through the keys of each cookie decrypting them and ---
  // and checking them against the payloadKey that was submitted. If a match is
  // found, the value associated cookie is removed and the function returns the
  // arguments called if successful, else null.
  removeCookie(payloadKey) {
    let cookieKey;
    this.cookies.keys().forEach((key) => {
      cookieKey = this.crypto.decrypt(key) === payloadKey ? key : null;
    });
    return cookieKey ? this.cookies.remove(cookieKey) && "REMOVED" : null;
  }
}
