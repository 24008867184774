import Vue from "vue";
import Vuex from "vuex";

// Modules

import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import moduleNote from "./notifications/moduleNote.js";
import moduleAuth from "./auth/moduleAuth.js";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import createPlugin from 'logrocket-vuex'

import LogRocket from 'logrocket';
const ls = new SecureLS({
  isCompression: false
});
const environment = process.env.NODE_ENV

let store

if (environment != 'development') {


  LogRocket.init('nkcvz6/public-digital')

  const logrocketPlugin = createPlugin(LogRocket, function (mutation) {

    if (mutation.type === 'auth/UPDATE_USER_INFO' && mutation.payload.fullName) {
      var mp = mutation.payload

      LogRocket.identify(mp.id, {
        name: mp.fullName,
        firstName: mp.firstName,
        lastName: mp.lastName,
        id: mp.id,
        notifications: mp.notifications,
        role: mp.role,
        trackedCases: mp.trackedCases,
        email: mp.email,
      });

    }





    return mutation;
  })

  Vue.use(Vuex);

  store = new Vuex.Store({
    plugins: [
      logrocketPlugin,
      createPersistedState({
        storage: {
          getItem: function (key) {
            var getItem = ls.get(key);
            
           if (key === 'vuex' && getItem){
             getItem = JSON.parse(getItem)
             getItem.notifications.axiosAvailable = false
             getItem.notifications.refreshDownloadNotification = 0
             getItem = JSON.stringify(getItem)
                   
           }
           return getItem;
         },
          setItem: function (key, value) {
            const newValue = ls.set(key, value);

            return newValue;
          },
          removeItem: (key) => ls.remove(key),
        },
      }),
    ],
    modules: {
      auth: moduleAuth,
      notifications: moduleNote,
      //notify: moduleNotification,
      app,
      appConfig,
      verticalMenu,

    },
  });


} else {


  Vue.use(Vuex);



  store = new Vuex.Store({
    plugins: [
      //logrocketPlugin,
      createPersistedState({
        storage: {
          getItem: function (key) {
             var getItem = ls.get(key);

            if (key === 'vuex' && getItem){
              getItem = JSON.parse(getItem)
              getItem.notifications.axiosAvailable = false
              getItem = JSON.stringify(getItem)
                    
            }
            return getItem;
          },
          setItem: function (key, value) {
            const newValue = ls.set(key, value);

            return newValue;
          },
          removeItem: (key) => ls.remove(key),
        },
      }),
    ],
    modules: {
      auth: moduleAuth,
      notifications: moduleNote,
      app,
      appConfig,
      verticalMenu,

    },
  });
}

export default store;