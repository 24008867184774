export default [
  {
    path: "/dashboard/main",
    name: "home-main",
    //component: () => import("@/views/dashboard/main-home/MainHome.vue"),
    component: () => import("@/views/dashboard/dashboard/Ecommerce.vue"),
  },
  {
    path: "/dashboard/generate-mailing",
    name: "generate-mailing",
    //component: () => import("@/views/dashboard/main-home/MainHome.vue"),
    component: () => import("@/views/public-digital/GenerateMailing.vue"),
    
    meta: {
      pageTitle: "Generate Mailing",
      action: "get",
      reference: "siteOwner",
    },
  },
  {
    path: "/dashboard/claims-action",
    name: "claims-action",
    component: () => import("@/views/public-digital/ClaimsAction.vue"),
    props: true,
    meta: {
      pageTitle: "Claims Action",
      action: "get",
      reference: "siteOwner",
    },
  },
  {
    path: "/dashboard/crawler-activity",
    name: "crawler-activity",
    component: () => import("@/views/dashboard/crawler-stats/CrawlerStats.vue"),
    meta: {
      action: "get",
      reference: "siteOwner",
    },
  },
  {
    path: "/dashboard/county-subscriptions",
    name: "county-subscriptions",
    component: () => import("@/views/dashboard/county-subscriptions/SubCounties.vue"),
    meta: {
      action: "get",
      reference: "siteOwner",
    },
  },
  {
    path: "/dashboard/case-upload",
    name: "case-upload",
    component: () => import("@/views/dashboard/case-upload/CaseUpload.vue"),
    meta: { 
      action: "get",
      reference: "siteOwner",
    },
  },
  {
    path: "/dashboard/start-crawler",
    name: "start-crawler",
    component: () => import("@/views/public-digital/StartCrawler.vue"),
    meta: { 
      action: "get",
      reference: "siteOwner",
    },
  },
  //{
  //  path: "/dashboard/analytics",
  //  name: "dashboard-analytics",
  //  component: () => import("@/views/dashboard/analytics/Analytics.vue"),
  //}, 
];
