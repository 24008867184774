export default {
  updateNotifications({ commit }, newState) {
    commit("UPDATE_NOTIFICATIONS", newState);
  },
  updateSelectedNotification({ commit }, newState) {
    commit("UPDATE_SELECTED_NOTIFICATION", newState);
  },
  updateSearchQuery({ commit }, newState) {
    commit("UPDATE_SEARCH_QUERY", newState);
  },
   showDownloadNotification({ commit }, newState) {
     commit("UPDATE_SHOW_NOTIFICATION", newState);
   },
   downloadNotificationType({ commit }, newState) {
     commit("UPDATE_DOWNLOAD_NOTIFICATION_TYPE", newState);
   },
   refreshDownloadNotification({ commit }, newState) {
     commit("REFRESH_DOWNLOAD_NOTIFICATION_TYPE", newState);
   },
   axiosAvailable({ commit }, newState) {
    commit("REFRESH_AXIOS_AVAILABLE", newState);
  },
};
