export default {
  UPDATE_SEARCH_QUERY(state, newState) {
    state.searchQuery = newState;
  },
  UPDATE_NOTIFICATIONS(state, newState) {
    state.notificationsCount = newState.length;
    state.notifications = JSON.stringify(newState);
  },
  UPDATE_SELECTED_NOTIFICATION(state, newState) {
    state.selectedNotification = newState;
  },
  UPDATE_SHOW_NOTIFICATION(state, newState) {
    state.showDownloadNotification = newState;
  },
  UPDATE_DOWNLOAD_NOTIFICATION_TYPE(state, newState) {
    state.downloadNotificationType = newState;
  },
  REFRESH_DOWNLOAD_NOTIFICATION_TYPE(state, newState) {
    state.refreshDownloadNotification = newState;
  },
  REFRESH_AXIOS_AVAILABLE(state, newState) {
    state.axiosAvailable = newState;
  },
};
