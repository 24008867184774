const state = {
  Notifications: "",
  notificationsCount: 0,
  selectedNotification: "",
  searchQuery: "test",
  showDownloadNotification: false,
  downloadNotificationType: null,
  refreshDownloadNotification: 0,
  axiosAvailable: false
};

export default state;
