export default [
  {
    path: "/mailing-exports",
    name: "mailing-exports",
    component: () => import("@/views/forms/form-wizard/FormWizard.vue"),
    meta: {},
  },
  {
    path: "/export-history",
    name: "export-history",
    component: () => import("@/views/public-digital/ExportHistory.vue"),
    meta: {
      pageTitle: "Export History",
    },
  },
  {
    path: "/templates",
    name: "templates",
    component: () => import("@/views/public-digital/Templates.vue"),

  },
];
