export default [{
    path: "/user-settings",
    name: "user-settings",
    component: () =>
      import("@/views/public-digital/manage-settings/UserSettings.vue"),
    meta: {
      action: "get",
      resource: "accountUser",
      pageTitle: "User Settings",
      breadcrumb: [{
          text: "Manage",
        },
        {
          text: "User Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/account-settings",
    name: "account-settings",
    component: () =>
      import("@/views/public-digital/manage-settings/AccountSettings.vue"),
    meta: {
      action: "get",
      resource: "accountAdmin",
      pageTitle: "Account Settings",
      breadcrumb: [{
          text: "Manage",
        },
        {
          text: "Account Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-remove-users",
    name: "add-remove-users",
    component: () =>
      import("@/views/public-digital/manage-settings/AddRemoveUsers"),
    meta: {
      action: "get",
      resource: "accountAdmin",
      pageTitle: "Add/Remove Users",
      breadcrumb: [{
          text: "Manage",
        },
        {
          text: "Add/Remove Users",
          active: true,
        },
      ],
    },
  },
];