import {
  Ability
} from "@casl/ability";
import {
  initialAbility
} from "./config";
import store from "@/store/index";
const checkAbility = function () {
  var userAbility;
  try {
    if (store.getters["auth/userData"].ability.length > 0) {
      userAbility = store.getters["auth/userData"].ability;
    } else {
      userAbility = initialAbility;
    }
  } catch {
    userAbility = initialAbility;
  }

  return userAbility;
};
export default new Ability(checkAbility());