var Crypto = require("crypto-js");
import Cookie from "js-cookie";
import { v4 as uuidv4 } from "uuid";

// THIS CLASS IS USED FOR THE PURPOSE OF ENCRYPTING AND DECRYPTING VALUES
// ASSOCIATED WITH LOCAL STORAGE, AS WELL AS COOKIE STORAGE.
export default class Secure {
  constructor(
    cookieName = process.env.VUE_APP_COOKIE_KEY,
    storageKey = process.env.VUE_APP_STORAGE_KEY
  ) {
    this.cookieName = storageKey;
    this.storageKey = cookieName;
    this.encryptionToken = "NyGkww5X2F5tuvoUcZIU9JViN2ICJgS1" || uuidv4();
    Cookie.set(this.cookieName, this.encryptionToken, {
      secure: true,
      expires: 180,
    });
  }
  // Check if JSON can be parsed ------------------------------------------
  checkJSONParse(payload) {
    var test;
    try {
      test = JSON.parse(payload);
    } catch {
      test = payload;
    }
    return test;
  }
  // Check if JSON can be stringified -------------------------------------
  checkJSONString(payload) {
    var test;
    try {
      test = JSON.stringify(payload);
    } catch {
      test = payload;
    }
    return test;
  }
  // Encrypt values using AES ---------------------------------------------
  encrypt(value, token) {
    // Check if new token has been submitted as argument ------------------
    // if not use instance token
    if (!token) {
      token = this.encryptionToken;
    }
    value = this.checkJSONString(value);
    const encrypted = Crypto.AES.encrypt(value, token).toString();
    return encrypted;
  }
  // Decrypt Values using AES ---------------------------------------------
  decrypt(value, token) {
    var decrypted;
    // Check if new token has been submitted as argument ------------------
    // if not use instance token ----
    if (!token) {
      token = this.encryptionToken;
    }
    value = this.checkJSONParse(value);
    if (value) {
      // Test for JSON parse - Also if Utf8 -------------------------------
      try {
        const bytes = Crypto.AES.decrypt(value, token);
        decrypted = this.checkJSONParse(bytes.toString(Crypto.enc.Utf8));
      } catch {
        decrypted = "";
      }
    }
    return decrypted;
  }
  // Encrypt JSON object using AES ----------------------------------------
  encryptObject(object, token) {
    // Check if new token has been submitted as argument ------------------
    // if not use instance token ----
    if (!token) {
      token = this.encryptionToken;
    }
    var crypticObject = {};
    // Looping through all keys and values in the object encrypting, ------
    //then storing values in a new object to be returned.
    Object.entries(object).forEach(([key, value]) => {
      const objKey = this.encrypt(key, token);
      const objValue = this.encrypt(value, token);
      crypticObject[objKey] = objValue;
    });
    return crypticObject;
  }
  // Decrypt JSON object using AES ----------------------------------------
  // NOTE: THIS FUNCTION RETURNS A DICTIONARY CONTAINING DECRYPTED AND
  // ENCRYPTED VALUES AS WELL AS AN ENCRYPTED AND DECRYPTED KEY
  decryptObject(object, token) {
    // Check if new token has been submitted as argument ------------------
    // if not use instance token ----
    if (!token) {
      token = this.encryptionToken;
    }
    var decrypticObject = {};
    // Looping through all keys and values in the object decrypting, ------
    // then storing values in a new object to be returned.
    Object.entries(object).forEach(([key, value]) => {
      const objKey = this.checkJSONParse(
        this.decrypt(this.checkJSONParse(key), token)
      );
      const objValue = this.checkJSONParse(
        this.decrypt(this.checkJSONParse(value), token)
      );
      decrypticObject[objKey] = {
        dValue: objValue,
        eKey: key,
        eValue: value,
      };
    });
    return decrypticObject;
  }
  // Decrypt JSON object using AES ----------------------------------------
  // NOTE: THIS FUNCTION RETURNS A DICTIONARY CONTAINING ONLY THE
  // DECRYPTED KEYS AND DECRYPTED VALUES.
  decryptObjectSimple(object, token) {
    // Check if new token has been submitted as argument ------------------
    // if not use instance token ----
    if (!token) {
      token = this.encryptionToken;
    }
    var decrypticObject = {};
    // Looping through all keys and values in the object decrypting, then -
    // storing values in a new object to be returned.
    Object.entries(object).forEach(([key, value]) => {
      const objKey = this.checkJSONParse(
        this.decrypt(this.checkJSONParse(key), token)
      );
      const objValue = this.checkJSONParse(
        this.decrypt(this.checkJSONParse(value), token)
      );
      decrypticObject[objKey] = objValue;
    });
    return decrypticObject;
  }
}
