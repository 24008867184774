// DEFAULT USER PATTERN
const userDefaults = {
  id: true,
  email: true,
  firstName: true,
  lastName: true,
  fullName: true,
  username: true,
  avatar: true,
  providerAvatar: true,
  baseAvatar: true,
  verified: true,
  role: true,
  refreshToken: true,
  ability: [],
  idToken: true,
  notifications: 0,
  extras: {
    eCommerceCartItemsCount: true,
  },
};

// DEFAULT STATE
const state = {
  envelopeId: null,
  costPerLetter: null,
  version: 'v0.0.1',
  isSetupComplete: false,
  baseAvatar: "",
  stateAvatar: "",
  accountType: "firm",
  accountTypeAllCaps: "FIRM",
  trackedCases: 0,
  userData: userDefaults,
};

export default state;
