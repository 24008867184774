export default [{
    path: "/compliance",
    name: "compliance",
    component: () => import("@/views/public-digital/Compliance.vue"),
    meta: {
      pageTitle: "Compliance",
    }, 
  },




];