/* THIS CLASS HANDLES THE BACKEND AUTHENTICATION. THE RESPONSE FROM THE BACKEND
   INCLUDES USER'S FIRST AND LAST NAME WHICH IS STORED IN THE DATABASE, AS WELL
   AS CUSTOM CLAIMS PARSING FROM FIREBASE'S ADMIN SDK. 
   (CURRENTLY PYTHON & DJANGO)
*/
export class AuthUser {
  /* CONSTRUCTOR TAKES USER INFORMATION FROM FIREBASE'S 'auth().currentUser' ---
     CONSTRUCTOR ALSO CREATES AN AXIOS INSTANCE WITH THE CURRENT ID TOKEN
     PRESENTED BY FIREBASE. IT IS SET AS THE 'BEARER' FOR JWT AUTH ON ON
     THE BACKEND SERVER RUNNING THE (DJANGO REST FRAMEWORK CURRENTLY).
     THE TOKEN IS PARSED BY THE FIREBASE ADMIN SDK AND VERIFIED FOR AUTHENTICITY.
  */
  constructor(
    l1,
    resultUser,
    currentUser,
    refreshToken,
    idToken,
    firstName,
    lastName
  ) {
    this.l1 = l1;
    this.resultUser = resultUser;
    this.refreshToken = refreshToken;
    this.idToken = idToken;
    this.currentUser = currentUser;
    this.firstName = this._cap(firstName);
    this.lastName = this._cap(lastName);
    this.claims = null;
    this.notifyCount = {};
    this.l1.$AuthAxios.createInstance(this.idToken);
  }
  // '_cap' FUNCTION CONVERTS FIRST CHARACTER LOWERCASE TO UPPERCASE -----------
  _cap = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  /* THIS IS AN ASYNC FUNCTION THAT PERFORMS A GET REQUEST TO THE DJANGO REST --
     BACKEND SERVER. PARAMS ARE FIRST AND LAST AND UID. THIS FUNCTION IS USED
     FOR BOTH SIGN UP AND SIGN IN. RESPONSE CONTAINS ACL CLAIMS AND OTHER USER
     INFORMATION.
     API ENDPOINT : 'www.backend:8888/auth-api/verify-user/'
  */
  async _callVerifyAuthApi() {
    var userResult;
    await this.l1.$AuthAxios.instance
      .get("/auth-api/verify-user/", {
        params: {
          uid: this.currentUser.uid,
          firstName: this.firstName,
          lastName: this.lastName,
        },
      })
      .then(async (res) => {
        this.full_service = res.data.full_service;
        this.claims = res.data.claims;
        this.firstName = res.data.claims.firstName;
        this.lastName = res.data.claims.lastName;
        this.notifyCount = res.data.notify_count;
        this.isSetupComplete = res.data.is_setup_complete;
        userResult = res;
        await this._commitUser().then(() => {
          return;
        });
        return;
      })
      .catch((error) => {
     
        if (error.response.data){
          throw error.response.data
        } else {
        console.log("verify-user endpoint ERROR ", error);
        throw new Error(error);
        }
      });
    return userResult;
  }
  /* THIS IS AN ASYNC FUNCTION THAT CALLS DISPATCHES USER STATE DATA TO THE VUEX
     STORE FOR PARSING AND STORAGE VIA ENCRYPTED LOCAL STORAGE.
  */
  async _commitUser() {
    this.currentUser.lastName = this.lastName;
    this.currentUser.firstName = this.firstName;
 
    await this.l1.$store
      .dispatch("auth/updateUserData", {
        full_service: this.full_service,
        isSetupComplete: this.isSetupComplete,
        idToken: this.idToken,
        refreshToken: this.refreshToken,
        user: this.currentUser,
        claims: this.claims,
        notifyCount: this.notifyCount,
      })
      .catch((err) => {
        console.log(" ERROR calling updateUserData store", err);
        throw new Error({ err });
      });
  }
  /* THIS FUNCTION IS THE SAME AS 'verifyUser' EXCEPT FOR PARSING USER NAME FROM
     THE SOCIAL AUTH PROVIDERS RESPONSE. -- DEPRECIATED ---
  */

  async updateSocialUser() {
    var userResult;
    await this._callVerifyAuthApi()
      .then((res) => {
        userResult = res;
      })
      .catch((error) => {
        throw new Error({ error });
      });
    return userResult;
  }
  /* THIS FUNCTION IS THE SAME AS 'updateSocialUser' EXCEPT DOES NOT PARSE USER
     NAME FROM AUTH PROVIDER.
  */

     _setAxiosAvailable(){
      this.l1.$store.dispatch("notifications/axiosAvailable", true);
    }
    async verifyUser() {
      this._setAxiosAvailable()
    var userResult;
    await this._callVerifyAuthApi()
      .then((res) => {
        userResult = res;
        return;
      })
      .catch((error) => {
        
        throw error
      });
    return userResult;
  }
}
