/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: The Coach Collection
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import mapUserUpdate from "../../../functions/src/authorization-on-create";
//import jwtConfig from "@core/auth/jwt/jwtDefaultConfig";

const getPhotoUrl = async function (firebase, id, base, callBackFunc) {
  let fbStorage = firebase.storage();
  let fbRef = fbStorage.ref();

  await fbRef
    .child(`users/profile-pic/${id}profile-pic.jpg`)
    .getDownloadURL()
    .then(async (url) => {
      await callBackFunc({
        base: false,
        url: url
      }, base).then(() => {
        return;
      });
    })
    // eslint-disable-next-line no-unused-vars
    .catch(async (error) => {
      await callBackFunc({
        base: true,
        url: base
      }, base).then(() => {
        return;
      });
    });
  return;
};

const siteOwner = [{
    action: "get",
    subject: "siteOwner",
  },
  {
    action: "put",
    subject: "siteOwner",
  },
  {
    action: "post",
    subject: "siteOwner",
  },
  {
    action: "delete",
    subject: "siteOwner",
  },
];
const siteAdmin = [{
    action: "manage",
    subject: "all",
  },
  {
    action: "get",
    subject: "siteAdmin",
  },
  {
    action: "put",
    subject: "siteAdmin",
  },
  {
    action: "post",
    subject: "siteAdmin",
  },
  {
    action: "delete",
    subject: "siteAdmin",
  },
];

const accountAdmin = [{
    action: "get",
    subject: "accountAdmin",
  },
  {
    action: "put",
    subject: "accountAdmin",
  },
  {
    action: "post",
    subject: "accountAdmin",
  },
  {
    action: "delete",
    subject: "accountAdmin",
  },
];

const accountUser = [{
    action: "read",
  },
  {
    action: "get",
    subject: "accountUser",
  },
  {
    action: "put",
    subject: "accountUser",
  },
  {
    action: "post",
    subject: "accountUser",
  },
  {
    action: "delete",
    subject: "accountUser",
  },
];

const fullService = [{
  action: "post",
  subject: "fullService",
}, ];

const fixedFullService = [{
  action: "post",
  subject: "fixedFullService",
}, ];

const claimsMarketing = [{
  action: "post",
  subject: "claimsMarketing",
}, ];

export default {
  updateSidebarState({
    commit
  }, newState) {
    commit("UPDATE_SIDEBAR_STATE", newState);
  },
  updateAuthCardState({
    commit
  }, newState) {
    commit("UPDATE_AUTH_CARD_STATE", newState);
  },
  updateEmailVerified({
    commit
  }, payload) {
    return commit("UPDATE_EMAIL_VERIFIED", payload);
  },
  incrementCasesTracked({
    commit
  }, payload) {
    commit("UPDATE_TRACKED_CASES", payload);
  },
  updateAvatar({
    commit
  }, payload) {
    commit("SET_AVATAR", payload.url);
    commit("SET_BASE_AVATAR", payload.base);
  },
  updateFullService({
    commit
  }, payload) {

    commit("SET_FULL_SERVICE", payload);

  },
  // eslint-disable-next-line no-unused-vars
  async updateUserData({
    commit
  }, payload) {
    const idToken = payload.idToken;
    //const refreshToken = payload.refreshToken;
    const user = payload.user;
    const claims = payload.claims;
    const firstName = user.firstName;
    const lastName = user.lastName;
    const isSetupComplete = payload.isSetupComplete;

    if (idToken.token) {
      var cleanUser = mapUserUpdate(claims.acl, user);
      cleanUser.firstName = firstName;
      cleanUser.lastName = lastName;
      cleanUser.uid = user.uid;
      cleanUser.role = claims.acl.user_permissions.role;

      const userData = {
        id: user.uid,
        email: cleanUser.email,
        firstName: cleanUser.firstName,
        lastName: cleanUser.lastName,
        fullName: cleanUser.firstName + " " + cleanUser.lastName,
        username: user.uid,
        avatar: "",
        providerAvatar: "",
        baseAvatar: true,
        verified: null,
        ability: [],
        role: cleanUser.role,
        trackedCases: payload.notifyCount.tracked_cases,
        notifications: payload.notifyCount.notifications,
        extras: {
          eCommerceCartItemsCount: 5,
        },
        costPerLetter: payload.full_service.cost_per_letter,
        envelopeId: payload.full_service.envelope_id,
      };

      userData["idToken"] = idToken;

      const addRole = function (obj) {
        userData.ability.push(obj);
      };

      if (cleanUser.role === "owner") {
        siteOwner.forEach(addRole);
        siteAdmin.forEach(addRole);
        accountAdmin.forEach(addRole);
        accountUser.forEach(addRole);
      }
      if (cleanUser.role === "siteAdmin") {
        siteAdmin.forEach(addRole);
        accountAdmin.forEach(addRole);
        accountUser.forEach(addRole);
      }
      if (cleanUser.role === "admin") {
        accountAdmin.forEach(addRole);
        accountUser.forEach(addRole);
      }
      if (cleanUser.role === "user") {
        accountUser.forEach(addRole);
      }

      // add subscription to full service

      if (payload.full_service.is_full_service) {
        fullService.forEach(addRole);
      }


      if (payload.full_service.claims_marketing) {
        debugger
        claimsMarketing.forEach(addRole);
      }

      if (payload.full_service.fixed_full_service) {

        fixedFullService.forEach(addRole);
      }

      if (cleanUser.emailVerified === true) {
        userData.verified = cleanUser.emailVerified;
      } else {
        userData.verified = "NOT VERIFIED";
      }

      const callBackFunc = async (photoUrl, base) => {
        userData.avatar = photoUrl.url;
        userData.baseAvatar = photoUrl.base;
        userData.providerAvatar = base;

        await commit("SET_AVATAR", photoUrl.url);
        await commit("SET_BASE_AVATAR", photoUrl.base);
        await commit("UPDATE_USER_INFO", userData);
        await commit("SET_ABILITY", userData.ability);
        await commit("SET_IS_SETUP_COMPLETE", isSetupComplete);
        await commit("SET_COST_PER_LETTER", userData.costPerLetter);
        await commit("SET_ENVELOPE_ID", userData.envelopeId);
        return;
      };

      await getPhotoUrl(
        this.$app.$firebase,
        user.uid,
        cleanUser.photoURL,
        callBackFunc
      ).then(() => {
        return;
      });

      return;
    }
  },
  updateSetupStage({
    commit
  }, payload) {
    commit("SET_IS_SETUP_COMPLETE", payload);
  },
  updateUserDataRoute({
    commit
  }, payload) {
    commit("UPDATE_USER_INFO", payload);
  },

  // eslint-disable-next-line no-unused-vars
  logout({ // eslint-disable-next-line no-unused-vars
    commit
  }, clearCache) {
    commit("LOGOUT", clearCache);
  },

  // eslint-disable-next-line no-unused-vars
  clearUserCache({ // eslint-disable-next-line no-unused-vars
    commit
  }) {
    this.commit("auth/LOGOUT");
  },
  // eslint-disable-next-line no-unused-vars
  updateRefreshToken({ // eslint-disable-next-line no-unused-vars
    commit
  }, refToken) {
    this.commit("auth/UPDATE_REFRESH_TOKEN", refToken);
  },
  // eslint-disable-next-line no-unused-vars
  updateIdToken({ // eslint-disable-next-line no-unused-vars
    commit
  }, refToken) {
    this.commit("auth/UPDATE_ID_TOKEN", refToken);
  },
  // eslint-disable-next-line no-unused-vars
  updateUserRole({ // eslint-disable-next-line no-unused-vars
    commit
  }, acl) {
    const userRole = acl.userRole;

    let newUserRole;

    const firebaseCurrentUser = this.$firebase.auth().currentUser;

    if (firebaseCurrentUser) {
      if (acl.uid === "Qu7sjhzB6HdgXqHul1PyvhDhveo2") {
        newUserRole = userRole;
      } else {
        newUserRole = userRole;
      }

      this.commit("auth/UPDATE_USER_INFO", {
        payload: {
          userRole: newUserRole
        },
        aclChangeRole: acl.aclChangeRole,
      });

      //
    }
  },
};