import Vue from "vue";
import {
  ToastPlugin,
  ModalPlugin
} from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import {
  v4 as uuidv4
} from "uuid";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

/* TODO:  CREATE CASE TRACKER PAGE.
 * USE TABLE FROM SEARCH FILINGS TO SHOW TRACKED CASES.
 * INCLUDE SOME GENERAL INFORMATION ABOUT TRACKED CASES IE. CONTACTS MADE.
 */

/* TODO:  CREATE NOTIFICATIONS PAGE.
 * USE AND MODIFY TABLE FROM SEARCH FILINGS TO SHOW NOTIFICATIONS.
 * CREATE VUEX OBJECT AND WATCH WHEN ROUTING BETWEEN PAGES FOR NOTE CHANGE.
 * MODIFY NOTIFICATION DRAWER TO USE VUEX DATA. PROGRAM LOGIC.
 * INSIDE DJANGO PRE-SAVE FUNCTION CHECK FOR NOTIFICATION REQUEST AND SAVE IF.
 */

/* TODO:  CREATE EXPORT HISTORY / COMPLIANCE PAGE.
 * CREATE TABLE THAT SHOWS EXPORT HISTORY. SEARCH/FILTER.
 * ADD ABILITY TO 'DELETE', MARK AS MAILED AND DATE, RE-EXPORT.
 */

/* TODO:   CREATE SHOP FOR COUNTIES / LEADS PAGE.
 * PRICING SET FROM STRIPE? OR USE WEBHOOKS FOR STRIPE?
 * USE MAP PACK TO SHOW TENNESSEE OTHER STATES AND COUNTIES?
 * SHOW SOME PRICING -- REQUEST PRICE FOR MOST...
 * INTEGRATE STRIPE > AND SHOPPING CART.
 * CREATE PERSISTENT CART STATE DATA.
 * SHOW ESTIMATED MONTHLY REVENUE / ROI.
 */

/* TODO:  CREATE BILLING PAGE THAT SHOWS COUNTIES SUBSCRIBED TO. CANCEL SERVICE ETC.
 * ALLOW TO CANCEL SERVICE FROM PAGE?
 * INTEGRATE WITH STRIPE API.
 */

/* TODO:  CREATE USER SETTINGS.
 * ABLE TO EDIT BUSINESS INFO IF YOU USER IS AN ADMIN.
 * SIMPLE USER PROFILE. PHONE/EMAIL/ETC.
 * NOTIFICATION SETTINGS. (NEED TO BRAINSTORM)
 */

/* TODO:  CREATE SIMPLE CONTACT PAGE.
 * EMAIL ONLY? PHONE?
 * CONTACT FOR FAQ?
 */

// TODO:  ADD ABILITY TO IMPORT PDF COUNTY LISTS INTO EXPORTS IE. KNOX CO.
// TODO:  CREATE CRAWLER THAT CHECKS FOR SUCCESSFUL LOGINS TO TEST USER'S CREDS
//        ON TNCRTS.COM AND OTHERS.
// FIXME: ERROR ON INITIAL LOGIN > NOT ACQUIRING USER'S FULL NAME. CAUSING
//        PAGE LINKS NOT TO LOAD. PROBABLY SOMETHING TO DO WITH LOCAL STORAGE
//        WHEN UPDATING ICON FOR NEW NOTIFICATIONS.



import {
  CryptoCookie
} from "./cookies/cookieExchange";

const storeEncryptionToken =
  Vue.$cookies.get(process.env.VUE_APP_COOKIE_NAME) || uuidv4();

Vue.$cookies.set(
  process.env.VUE_APP_COOKIE_NAME,
  storeEncryptionToken,
  Infinity
);

// Firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import fbConfig from "./auth/firebase";

// Initialize Firebase
firebase.initializeApp(fbConfig);

Vue.prototype.$firebase = firebase; // This prototype should work.

import encrypt from "./store/auth/encryptionScript";
Vue.prototype.$cryptograph = new encrypt(storeEncryptionToken);

Vue.prototype.$CryptoCookie = new CryptoCookie(Vue);

import router from "./router";
import store from "./store";
import App from "./App.vue";

// import i18n & tailwindcss
// import i18n from "@/libs/i18n";
import "tailwindcss/tailwind.css";

// Vue-2 Filters
import Vue2Filters from "vue2-filters";

Vue.use(Vue2Filters);

// register directive v-money
import money from "v-money";
Vue.use(money, {
  precision: 4
});

// Axios APIs

import {
  AuthAxios
} from "./api/axios";
Vue.prototype.$AuthAxios = new AuthAxios(store);



const getComponent = function (componentName) {
  let component = null;
  let parent = this.$parent;
  while (parent && !component) {
    if (parent.$options.name === componentName) {
      component = parent;
    }
    parent = parent.$parent;
  }
  return component;
};


 Vue.prototype.$GetDownloads = (axios, store) => {

 axios.instance
   .get("/api-access/exports/templates/download-status/", {})
   .then(function (res) {
     
    if (!res.data){
      debugger
      res.data.forEach((ele) => {
        store.dispatch(
         "notifications/downloadNotificationType",
         ele.complete ? "complete" : "active"
       );
       store.dispatch("notifications/showDownloadNotification", true);//
  
     });

     if (res.data.length <= 1) {
      var increment = store.getters["notifications/refreshDownloadNotification"] + 1;

      store.dispatch("notifications/showDownloadNotification", false);

      store.dispatch("notifications/refreshDownloadNotification", increment);
     }



    } 

     

   })
   .catch((error) => {

     console.log(error);
   });

  
 };
Vue.prototype.$GetNotifications = (axios, store) => {

  console.log(axios, store)
   axios.instance
     .get("/api-access/notifications/notification-dropdown/", {})
     .then(function (res) {
       store.dispatch("notifications/updateNotifications", res.data);
     })
     .catch((error) => {

       console.log(error);
     });
};
// Vue.prototype.$BAAP = (axios, store) => {
//   debugger
//   console.log(axios, store)
// axios.instance
//   .get("/api-access/notifications/notification-dropdown/", {})
//   .then(function (res) {
//     store.dispatch("notifications/updateNotifications", res.data);
//   })
//   .catch((error) => {
//     debugger;
//     console.log(error);
//   });
//};


 
Vue.prototype.$getComponent = getComponent;

// Global Components
import "./global-components";

// 3rd party plugins

import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";

// Axios Mock Adapter
import "@/@fake-db/db";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  // i18n,
  render: (h) => h(App),
});
store.$app = app;
app.$mount("#app");

// eslint-disable-next-line no-unused-vars