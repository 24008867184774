import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import {
  AuthUser
} from "../store/auth/authScript";

import {
  routeProtection
} from "@/libs/acl/routerProtection";
import {
  getHomeRouteForLoggedInUser
} from "@/auth/utils";

// Routes
import apps from "./routes/apps";
import caseTracker from "./routes/case-tracker";
import cases from "./routes/cases.js";
import dashboard from "./routes/dashboard";
import pages from "./routes/pages";
import exports from "./routes/exports.js";
import manage from "./routes/manage";
import notifications from "./routes/notifications";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [{
      path: "/",
      redirect: {
        name: "home-main"
      }
    },
    ...manage,
    ...caseTracker,
    ...apps,
    ...dashboard,
    ...pages,
    ...cases,
    ...exports,
    ...notifications,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});
// eslint-disable-next-line no-unused-vars

const waitForStorageToBeReady = async (to, _, next) => {
  const l1 = router.app;

  function genURL(rExp, aText, baseUrl, bDebug = false) {
    let theURL;

    theURL = new URL(baseUrl);

    theURL.searchParams.set("firstName", l1.$store.getters["auth/firstName"]);
    theURL.searchParams.set("lastName", l1.$store.getters["auth/lastName"]);
    theURL.searchParams.set("zid", l1.$store.getters["auth/id"]);
    theURL.searchParams.set("from", "app_contact");
    if (bDebug) {
      // Display the key/value pairs
      for (var pair of theURL.searchParams.entries()) {
        console.debug(pair[0] + " = '" + pair[1] + "'");
      }
      console.debug(theURL);
    }
    return theURL;
  }

  if (to.path === "/forms/form-layout") {
    ("use strict");

    var url = genURL(
      /(^\s*\/\/|\s*[^:]\/\/).*\s*$|\s*\/\*(.|\n)+?\*\/\s*$/gm, // single/multi-line comments
      // /(^\s*\/\/.*|\s*[^:]\/\/.*)/g								// single-line comments
      [
        "These should work:",
        "",
        "// eslint-disable-next-line no-unused-vars",
        "lockPref(	'keyword.URL',\t\t'https://duckduckgo.com/html/?q=!+'	)\t//      test",
        "/*",
        "	* bla bla    ",
        "*/",
        "",
        "/* bla bla */",
        "",
        "// bla bla ",
        "",
        "These shouldn't work:",
        'console.log("http://foo.co.uk/")',
        'var url = "http://regexr.com/foo.html?q=bar"',
        'alert("https://mediatemple.net")',
      ],
      "https://publicdigital.net/contact/",
      true
    );
    console.info(url, url.toString());

    window.location = url.href;
    return;
  }

  if (to.path === "/pages/pricing") {
    window.location = "https://publicdigital.net/#pricing";
  }
  if (to.path === "/pages/faq") {
    window.location = "https://publicdigital.net/#faq";
  }

  // Declaring vars -------------------------------------------------------
  var firstName;
  var lastName;
  var verifiedCheck;
  var currentUser;
  var idToken;
  var refreshToken;
  var userRole;
  var protector;
  var setupCompleteCheck;
  await firebase.auth().onAuthStateChanged(async (userResult) => {
    // await userResult ---------------------------------------------------
    await userResult;
    // Logged in Test -----------------------------------------------------
    if (userResult) {
      // Firebase Instance Properties -------------------------------------
      currentUser = await firebase.auth().currentUser;
      idToken = await currentUser.getIdTokenResult(false);
      refreshToken = currentUser.refreshToken;
      // SignUp Properties -- Check for cookie ----------------------------
      try {
        firstName = l1.$CryptoCookie.getCookie("firstName").cookie || null;
        lastName = l1.$CryptoCookie.getCookie("lastName").cookie || null;
      } catch {
        firstName = "";
        lastName = "";
      }
      // Create Backend Authentication Instance ---------------------------
      const authUser = new AuthUser(
        l1,
        userResult,
        currentUser,
        refreshToken,
        idToken,
        firstName,
        lastName
      );


      // Call verifyUser. This will call the backend api to set up user if
      // new, or verify user if current. Also sets state and variables.
      
      await authUser
        .verifyUser()
        .then(async (apiResult) => {
          // await router.app.$GetNotifications(
          //   router.app.$AuthAxios,
          //   router.app.$store
          // );

          // Set Auth State and Other State vars --------------------------
          firstName = await apiResult.data.claims.firstName;
          lastName = apiResult.data.claims.lastName;
          verifiedCheck = currentUser.emailVerified;
          setupCompleteCheck = apiResult.data.is_setup_complete;
          userRole = await router.app.$store.getters["auth/role"];

          // Create Route Protection Instance  ----------------------------
          
          protector = new routeProtection();
          protector.test(to);
         
          if (!protector.checkAbility) {
            // If route not authorized re-route to not-authorized
            return next({
              name: "misc-not-authorized"
            });
          }
          // Redirect if Email is Not Verified and not to auth-verify-email
          if (!verifiedCheck && to.name != "auth-verify-email") {
            return next({
              name: "auth-verify-email"
            });
          }

          // Redirect if User has not completed account setup.
          if (
            setupCompleteCheck !== true &&
            to.name !== "create-account" &&
            to.name !== "auth-verify-email" &&
            to.name !== "auth-forgot-password"
          ) {
            return next({
              name: "create-account"
            });
          }

          // Redirect if logged in ----------------------------------------
          if (
            to.meta.redirectIfLoggedIn &&
            verifiedCheck &&
            to.name !== "auth-forgot-password"
          ) {
            var homeRoute = getHomeRouteForLoggedInUser(
              userRole ? userRole : null
            );

            return next(homeRoute);
          }

          // Continue to next route ---------------------------------------

          return next();
        })
        .catch((error) => {
        
          if (error.detail === 'INCORRECT CLIENT VERSION'
          ) {
            if (to.name != "auth-login") {
              return next({
                name: "auth-login"
              });
            }
        
            window.location.reload()
          } else {
          // Sign Out User to be safe
          firebase.auth().signOut();
          // If Error > Logout User ---------------------------------------
          router.app.$store.dispatch("auth/logout", true);
          // Console log the Error ----------------------------------------
          console.log("VerifyAuthApi ERROR ", error);
          // Return to login screen > USER IS NOT LOGGED IN
          if (to.name != "auth-login") {
            return next({
              name: "auth-login"
            });
          }
        
          // Logged in >> continue to next route --------------------------
          return next();
        }
        });
    } else {
      // Sign Out User to be safe
      firebase.auth().signOut();
      // If Error > Logout User -------------------------------------------
      router.app.$store.dispatch("auth/logout", true);
      // Console log the Error --------------------------------------------
      // Create new routeProtection Instance & Test Route
      protector = new routeProtection();
      protector.test(to);
      // Return to login screen if not navigating to auth-login already ---
      // > USER IS NOT LOGGED IN
      if (to.name != "auth-login" && !protector.checkAbility) {
        return next({
          name: "auth-login"
        });
      }
      // Move to next route -----------------------------------------------
      return next();
    }
  });
  return false;
};

router.beforeEach(waitForStorageToBeReady);
export default router;