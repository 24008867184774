import {
  Ability
} from "@casl/ability";
import {
  initialAbility
} from "./config";
import store from "@/store/index";

// export const canNavigate = (to) => {
//   let checkAbility;
//   to.matched.some((route) => {
//
//     checkAbility = ability.can(
//       route.meta.action || "read",
//       route.meta.resource
//     );
//   });
//
//   return checkAbility;
// };
//
// export const _ = undefined;

export class routeProtection {
  constructor() {
    this.stor = store;
    this.userAbility = null;

    try {
      if (store.getters["auth/userData"].ability.length > 0) {
        this.userAbility = store.getters["auth/userData"].ability;
      } else {
        this.userAbility = initialAbility;
      }
    } catch {
      this.userAbility = initialAbility;
    }

    this.testAbility = new Ability(this.userAbility);
  }
  test(to) {
    const l1 = this;
    let checkAbility;

    to.matched.some(function (route) {

      checkAbility = l1.testAbility.can(
        route.meta.action || "read",
        route.meta.resource
      );
    });



    this.checkAbility = checkAbility;
  }
}