export default {
  notifications: (state) => state.notifications,
  notificationsCount: (state) => state.notificationsCount,
  selectedNotification: (state) => state.selectedNotification,
  searchQuery: (state) => state.searchQuery,
  showDownloadNotification: (state) => state.showDownloadNotification,
  downloadNotificationType: (state) => state.downloadNotificationType,
  refreshDownloadNotification: (state) => state.refreshDownloadNotification,
  axiosAvailable: (state) => state.axiosAvailable,
};
