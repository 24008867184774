//import state from "../notifications/moduleNoteState";

export default {
  costPerLetter: (state) => state.costPerLetter,
  envelopeId: (state) => state.envelopeId,
  isSetupComplete: (state) => state.isSetupComplete,
  accountType: (state) => state.accountType,
  accountTypeAllCaps: (state) => state.accountTypeAllCaps,
  trackedCases: (state) => state.trackedCases,
  id: (state) => state.userData.id,
  email: (state) => state.userData.email,
  firstName: (state) => state.userData.firstName,
  lastName: (state) => state.userData.lastName,
  fullName: (state) => state.userData.fullName,
  username: (state) => state.userData.username,
  startAvatar: (state) => state.userData.avatar,
  avatar: (state) => state.avatar,
  baseAvatar: (state) => state.baseAvatar,
  verified: (state) => state.userData.verified,
  role: (state) => state.userData.role,
  ability: (state) => state.userData.ability,
  extras: (state) => state.userData.extras,
  userData: (state) => state.userData,
  version: (state) => state.version,
};
