/*=========================================================================================
  File Name: moduleAuth.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: The Coach Collection
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleAuthState";
import mutations from "./moduleAuthMutations.js";
import actions from "./moduleAuthActions.js";
import getters from "./moduleAuthGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
