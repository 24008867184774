//import https from "https";
import * as axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
export class AuthAxios {
  constructor(store) {
    this.baseURL = process.env.VUE_APP_API_URL;

    this.store = store;
  }

  // Called to create instance with JTW token
  async createInstance(idToken) {
    const l1 = this;
    l1.idToken = idToken;
    var baseURL = this.baseURL;

    this.instance = axios.create({
      baseURL,
      //httpsAgent: new https.Agent({
      //  rejectUnauthorized: false,
      //}),
    });

    // TODO: CREATE A DYNAMIC TOKEN EXPIRATION CATCH THAT ALERTS THE USER THEIR SESSION
    // IS ABOUT TO EXPIRE.

    // Intercepts and checks request for token expiration and updates if necessary
    await this.instance.interceptors.request.use(async function(config) {

      
      config.headers.common['Version'] = process.env.VUE_APP_VERSION

    

      if (Date.parse(l1.idToken.expirationTime) - new Date() < 2000) {
        var currentUser = await firebase.auth().currentUser;
        const nIdToken = await currentUser.getIdTokenResult(true);
        l1.store.dispatch("auth/updateIdToken", nIdToken.token);
        l1.idToken = nIdToken;
        //const ms = Date.parse(nIdToken.expirationTime) - new Date();

        
        config.headers.common["Authorization"] = `Bearer ${nIdToken.token}`;

        return config;
      }

      // Auth Header is updated
      config.headers.common["Authorization"] = `Bearer ${l1.idToken.token}`;

      return config;
    });

    // TODO: FORCE LOGOUT IF ERROR....
    this.instance.interceptors.response.use(
      function(response) {
     
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function(error) {
        
        if (error.response.status === 999 || error.response.data.detail === 'INCORRECT CLIENT VERSION') {
  
          window.location.reload()
          console.log("Version out of sync", error);
        }
        console.log("error", error);
        return Promise.reject(error);
      }
    );
  }
}
