export default [{
  path: "/notifications",
  name: "notifications",
  component: () => import("@/views/public-digital/Notifications.vue"),
  meta: {
    action: "get",
    resource: "accountUser",
    pageTitle: "Notifications",
  },
},
{
  path: "/mail-stats",
  name: "mail-stats",
  //component: () => import("@/views/dashboard/main-home/MainHome.vue"),
  component: () => import("@/views/public-digital/MailStats.vue"),
  meta: {
    pageTitle: "Suitinfo.net",
  },
},
{
  path: "/calls",
  name: "calls",
  //component: () => import("@/views/dashboard/main-home/MainHome.vue"),
  component: () => import("@/views/public-digital/PhoneCalls.vue"),
  meta: {
    pageTitle: "Tracked Phone Calls",
  },
},



];