/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: The Coach Collection
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
//import jwtConfig from "@core/auth/jwt/jwtDefaultConfig";
import { initialAbility } from "@/libs/acl/config";
import firebase from "firebase/app";
import "firebase/auth";
function updateEncrypt(identityKey, newState, instance) {
  const iKey = instance.$app.$cryptograph.encrypt(identityKey);

  const iValue = instance.$app.$cryptograph.encrypt(JSON.stringify(newState));

  localStorage.setItem(iKey, iValue);

  return { iKey: iKey, iValue: iValue };
}
export default {
  SET_FULL_SERVICE(state, newState) {


    var rules = this.$app.$ability.rules

    // get index of rule

    var newRules = []

    for (var i = 0; i < rules.length; i++) {

      if (rules[i].subject != "fullService") {
        newRules.push(rules[i]) 
      }
    }

    if (newState === 'option1'){
      newRules.push({
        action: "post",
        subject: "fullService",
      })

    }

    this.$app.$ability.update(newRules);

    

  }, 
  SET_COST_PER_LETTER(state, newState) {
    state.costPerLetter = newState;
  },
  SET_ENVELOPE_ID(state, newState) {
    state.envelopeId = newState;
  },
  SET_IS_SETUP_COMPLETE(state, newState) {
    state.isSetupComplete = newState;
  },
  UPDATE_TRACKED_CASES(state, newState) {
    // TODO: REMOVE CRYPTOGRAPH

    state.trackedCases = state.trackedCases + newState;
  },
  UPDATE_SIDEBAR_STATE(state, newState) {
    state.sidebarState = newState;
  },
  UPDATE_AUTH_CARD_STATE(state, newState) {
    state.authCardState = newState.state;
    state.authCardType = newState.type;
  },
  UPDATE_EMAIL_VERIFIED(state, newState) {
    updateEncrypt("verified", newState, this, state);
  },
  LOGOUT(state, clearCache) {
    var newState = {};
    newState.id = "";
    newState.email = "";
    newState.firstName = "";
    newState.lastName = "";
    newState.aName = "";
    newState.username = "";
    newState.avatar = "";
    newState.verified = "";
    newState.role = "";
    newState.ability = "";
    newState.extras = "";
    newState.idToken = "";
    state.userData = newState;
    Object.keys(localStorage).forEach((key) => {
      localStorage.removeItem(key);
    });
    this.$app.$ability.update(initialAbility);
    if (!clearCache) {
      firebase.auth().signOut();
    }
  },
  // SET_ACL(state, payload) {
  //   this.$app.$ability.update(payload);
  // },
  async SET_ABILITY(state, payload) {
    // eslint-disable-next-line no-unused-vars
    let ability = this.$app.$ability;
    // eslint-disable-next-line no-unused-vars
    const unsubscribe = ability.on("update", ({ rules, target }) => {
      return;
      // `rules` is an array passed to `update` method
      // `target` is an Ability instance that triggered event
    });

    ability.update(payload);
    await unsubscribe();

    return;
  },
  SET_AVATAR(state, payload) {
    state.avatar = payload;
  },
  SET_BASE_AVATAR(state, payload) {
    state.baseAvatar = payload;
  },
  UPDATE_ID_TOKEN(state, payload) {
    state.userData.idToken = payload;
  },

  async UPDATE_USER_INFO(state, payload) {
    /*const l1 = this;
    this._vm.$ability.update(payload.ability);
    var newState = {};
    const dLocalStorage = this._vm.$cryptograph.decryptObject(localStorage);
    Object.entries(payload).forEach(([key, value]) => {
      const encVal = l1._vm.$cryptograph.encrypt(value);
      const encKey = l1._vm.$cryptograph.encrypt(key);
      const dLSK = dLocalStorage[key]
        ? JSON.stringify(dLocalStorage[key].dValue)
        : null;
      newState[encKey] = encVal;
      if (!dLSK) {
        localStorage.setItem(encKey, encVal);
      } else if (dLSK != JSON.stringify(value)) {
        localStorage.removeItem(dLocalStorage[key].eKey);
        localStorage.setItem(encKey, encVal);
      }
    });*/

    state.trackedCases = payload.trackedCases;
    state.userData = payload;

    return;
  },
};
